import React from 'react';
import Page from 'components/page';
import loadable from '@loadable/component';

import useStyles from './styles';

const Typography = loadable(() => import('@material-ui/core/Typography'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));

const LazyFaqBannerAuth = loadable(() => import('components/banners/in-app-banners/faq'));
const ReportProblemView = loadable(() => import('views/support/report-a-problem'));
const LazyFooter = loadable(() => import('components/footer'));

const ReportBugPage = () => {
  const styles = useStyles();

  return (
        <Page title="Support" description={'Get Support or help using Total Loyalty'}>
          <LazyFaqBannerAuth/>
          <Typography variant="h2" component="h1" className={styles.heading} align="center">
            Report a Problem
          </Typography>
          <Hidden smDown>
          <Typography variant="h4" component="p" className={styles.firstMessage} align="center">
           Please get in touch with any questions or problems
          </Typography>
          </Hidden>
          <Hidden mdUp>
            <Typography variant="h4" component="p" className={styles.firstMessage} align="center">
              Please get in touch with any <br/> questions or problems
            </Typography>
          </Hidden>
          <ReportProblemView/>
          <LazyFooter />
        </Page>
  );
};

export default ReportBugPage;
